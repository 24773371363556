let STATIC_TRANSLATE = {
    "((USER_TOKENS_POSITIVE))": "扇贝余额不足",
    "((SUPPLY_NEED_REFILL))": "当前补给配额已用完，请稍后再试",
    "((INSUFFICIENT_ITEMS))": "物品数量不足，无法装备",
    "((NAME_NO_CHANGE))": "名字无变化，取消改名操作",
    "((CHARACTER_INVALID))": "角色无效",
    "((HIT_MAX_EXPERIENCE))": "角色已达到当前版本最高等级，无法继续使用",
    "((EXCEED_PLAYER_LIMIT))": "已达到场次单用户角色数上限",
    "((NAME_TOO_LONG))": "角色名字过长",
    "((INVALID_NAME))": "角色名字无效",
    "((INVALID_NAMEHASH))": "角色名字过期，请刷新页面后重试",
    user_balance_positive: "空石余额不足，请前往空扇区钱包充值",
    cabin_template_owned_pkey: "当前模板已拥有",
    user_character_pinned_message_character_id_content_digest_key: "当前消息已收录",
    season_waitlist_pkey: "当前角色已加入审核队列"
};
export function translateError(e, overrides) {
    let msg = e + "";
    if (overrides) {
        for(let key in overrides)if (msg.includes(key)) return overrides[key];
    }
    for(let key in STATIC_TRANSLATE)if (msg.includes(key)) return STATIC_TRANSLATE[key];
    return msg;
}
